export default {
  meta_title: 'Verify Email — PennyLane',
  meta_description:
    'A Python library for quantum machine learning, automatic differentiation, and optimization of hybrid quantum-classical computations. Use multiple hardware devices, alongside TensorFlow or PyTorch, in a single computation.',
  meta_image: 'https://assets.cloud.pennylane.ai/pennylane_website/generic/thumbnail.png',
  cardContent: {
    title: 'Almost there!',
    description: `Email verification is required to log into your account — check your inbox for our verification email and follow the link. You’ll only need to do this once! \n\n Don’t see an email? Be sure to check that pesky spam box 👀`,
    resendButtonLabel: 'Resend verification email',
    backButtonLabel: 'Back to PennyLane.ai',
    footnote: 'Still nothing? Give us a shout at support@pennylane.ai',
    successMessage: 'Email sent successfully, please check your inbox!',
  },
}

import { useState } from 'react'
import MarkdownText from '../MarkdownText/MarkdownText'
import { Button } from 'component-library'
import { withPrefix } from 'gatsby'
import { get } from 'lodash'
import { useAuth0 } from '@auth0/auth0-react'
import { gql, useMutation } from '@apollo/client'
import { getCurrentEnvConfig } from '../../../config'
import verifyEmailContent from '../../content/auth/verify_email'
import AuthFooter from '../AuthFooter/AuthFooter'

const { cardContent } = verifyEmailContent

const config = getCurrentEnvConfig()
const pennyLaneBlueBackground =
  'https://assets.cloud.pennylane.ai/pennylane_website/backgrounds/pennylane_background_blue.png'

const VerifyEmail = ({ sessionToken }: { sessionToken: string }) => {
  const { logout } = useAuth0()

  const [verifyEmailError, setVerifyEmailError] = useState<string>('')
  const [verifyEmailSuccessMessage, setVerifyEmailSuccessMessage] =
    useState<string>('')

  const [verifyEmailMutation] = useMutation(SEND_VERIFICATION_EMAIL)

  const sendVerificationEmail = async () => {
    setVerifyEmailError('')
    setVerifyEmailSuccessMessage('')
    try {
      const verifyEmailResponse = await verifyEmailMutation({
        variables: {
          sessionToken: sessionToken,
        },
      })

      const userErrorMessage = get(
        verifyEmailResponse,
        'data.verificationEmail.userError.message'
      )
      const genericErrorMessages = get(verifyEmailResponse, 'errors')
      if (userErrorMessage)
        setVerifyEmailError(userErrorMessage ? userErrorMessage : '')
      else if (genericErrorMessages && genericErrorMessages.length)
        setVerifyEmailError(genericErrorMessages[0].message)
      else {
        setVerifyEmailSuccessMessage(cardContent.successMessage)
      }
    } catch (err) {
      console.error(err)
      setVerifyEmailError('Something went wrong, please try again later.')
    }
  }

  return (
    <div
      className="AuthLayout"
      style={{ backgroundImage: `url(${withPrefix(pennyLaneBlueBackground)})` }}
    >
      <div className="AuthCard">
        <h2 className="mb-6">{cardContent.title}</h2>
        <MarkdownText
          text={cardContent.description}
          className="AuthCard__description"
        />
        <Button
          label={cardContent.resendButtonLabel}
          onClick={sendVerificationEmail}
          type="primary"
          className="w-full mt-4"
          testId="resend-email-button"
        />
        <Button
          label={cardContent.backButtonLabel}
          type="basic"
          onClick={() => logout({ logoutParams: { returnTo: config.siteURL } })}
          className="w-full mt-6 text-center font-normal text-[#757575] my-6 underline"
        />
        {verifyEmailError && (
          <p className="text-error-1 my-2 flex items-center justify-center">
            <i className="bx bxs-error-circle mr-2"></i> {verifyEmailError}
          </p>
        )}
        {verifyEmailSuccessMessage && (
          <p className="text-primary-green-3 mt-2 mb-8 text-center">
            {verifyEmailSuccessMessage}
          </p>
        )}
        <p className="AuthCard__footnote">{cardContent.footnote}</p>
      </div>
      <AuthFooter />
    </div>
  )
}

export default VerifyEmail

export const SEND_VERIFICATION_EMAIL = gql(`
  mutation verificationEmail($sessionToken: String!) {
    verificationEmail(sessionToken: $sessionToken) {
      userError {
        field
        message
      }
    }
  }
`)
